
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MultiFileUploaderVideoModule } from 'client-website-ts-library/types/Forms/FieldTypes';
import { VideoRecorder } from 'client-website-ts-library/components';

@Component({
  components: {
    VideoRecorder,
  },
})
export default class FormMultiFileUploaderVideoModule extends Vue {
  @Prop()
  private readonly def!: MultiFileUploaderVideoModule;

  private get isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  handleDone(video: Blob | null) {
    if (video !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const file = video as any;

      if (!(video instanceof File)) {
        file.lastModifiedDate = new Date();
        file.name = 'Video';
      }

      this.$emit('add_files', [file as File]);
    }
  }
}
